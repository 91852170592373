import React, { useState, useEffect, useContext } from "react";
import { Text, View, Platform } from 'react-native'
import { Picker } from "@react-native-picker/picker";
import { getWidth, checkOutputVelocity } from '../../../api/dcm_api';
import { handleLoading } from '../../Need/'
import _ from 'underscore'
import { MEDIUM_B, BUTTON_PRIMARY, TEXT_B_RED, TEXT_B, getFont, DARK_GRAY, DEFAULT_FONT_BOLD } from "../../../style";
import { getUnity, DISTANCE, VELOCITY, QUANTITY_DISTANCE, converValue } from "../../../metrics";
import { UserContext } from '../../../utils/context'
import SinglePickerText from './SinglePickerText';

import { TextButton, ResultNode } from "../../General/Other";

export function SinglePicker(props){

  return (
    <View style={{margin: 2, flex: 1}}>
      <Picker
          selectedValue={props.selectedValue}
          onValueChange={(itemValue, _) => {
            props.onValueChange(itemValue)}}>
            { props.values.map( p => 
              <Picker.Item key={p.value} label={String(p.label)} value={p.value}/>) }
      </Picker> 
    </View>
  )
}



export default function ValueGeneric(props) {

  
  

  const { languages, code, metrics } = useContext(UserContext);


  const velocities = _.range(4,31,1)
    .map( q => { 
      return {
        "value":q, 
        "label":converValue(q, metrics, VELOCITY)
      }
    })

  const quantities = [ ..._.range(1,50,1), ..._.range(50,1500,10) ]
    .map( q => { 
      return {
        "value":q,
        "label":converValue(q, metrics, QUANTITY_DISTANCE)
      }
    })

  const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
  const [internalSetting, setInternalSetting] = useState(props.settings);
  const [internalMessage, setInternalMessage] = useState(null);
  const [internalMessageSuccess, setInternalMessageSuccess] = useState(null);

  const callCheckOutput = (idMachine, idFertilizer, w, v, q, idPaletta) => {
    

    checkOutputVelocity(idMachine, idFertilizer, w, v, q, idPaletta, code)
      .then( s => {
        if (s.response) {
          setInternalMessage(s.response.message)
          props.isValid(s.response.success)
          s.response.success && setInternalMessageSuccess(languages['parameter_set_correct_msg'][code])
        } else {
          setInternalMessage(s.error)
        }
      })
  }
  
  
  
  useEffect(() => {
    props.isValid(false) 

    
    setStateApi({isLoading: true, error: null, response: []})
    props.idFertilizer !== -1 && 
      props.idMachine !== -1 &&  
        getWidth(code, props.idFertilizer, 
          props.idMachine, 
          props.blade ? 1 : 0, 
          props.paletteSelected ? props.paletteSelected.id : -1).then( success => {
          if (success.response) {

            //success.response.widths.length > 0 ? props.isValid(true) : props.isValid(false)            
            const a = success.response.widths.map( rr => parseFloat(rr))
            const r = a.map( w => {
              return { 
                "value": w, 
                "label": w //converValue(w, metrics, DISTANCE)
              }
            }) 

            setStateApi({isLoading: false, error: null, response: r})
            if (r.length > 0) {

              const sett = {
                width: props.settings?.width || r[0].value, 
                velocity: props.settings?.velocity || velocities[0].value, 
                quantity: props.settings?.quantity ||  quantities[0].value}
              props.onSelectValue(sett)
              setInternalSetting(sett)

            }
            
          } else {
            setStateApi({isLoading: false, error: success.error, response: []})
          }  
          
      })
  }, [])

  const BodyWeb = () => {
    return (
      <View style={{marginTop: 30}}>
        <SinglePickerText title={languages["width"][code]} 
          selectedValue={  props.settings?.width }
          values={stateApi.response} 
          unity={getUnity(metrics, DISTANCE)}
          onValueChange={(w) => {
            const sett = {width: w, velocity: props.settings.velocity, quantity: props.settings.quantity}
            props.onSelectValue(sett)
            setInternalSetting(sett)
          }}/>
        <SinglePickerText title={languages["velocity"][code]} 
          selectedValue={  props.settings?.velocity }
          values={velocities} 
          unity={getUnity(metrics, VELOCITY)}
          onValueChange={(v) => {
            const sett = {width: props.settings.width, velocity: v, quantity: props.settings.quantity}
            props.onSelectValue(sett)
            setInternalSetting(sett)
          }}/>
        <SinglePickerText title={languages["quantity"][code]} 
          selectedValue={  props.settings?.quantity }
          values={quantities}
          unity={getUnity(metrics, QUANTITY_DISTANCE)}
          onValueChange={(q) => {
            const sett = {width: props.settings.width, velocity: props.settings.velocity, quantity: q}
            props.onSelectValue(sett)
            setInternalSetting(sett)
          }}/>
      </View>
    )
  }

  const BodyMobile = () => {
    return (
      <View>
          <View style={{flexDirection:'row'}}>
            <Text style={[TEXT_B, { textAlign: 'center', margin: 10, flex: 1}]}>{languages["width"][code]}</Text>
            <Text style={[TEXT_B, { textAlign: 'center', margin: 10, flex: 1}]}>{languages["velocity"][code]}</Text>
            <Text style={[TEXT_B, { textAlign: 'center', margin: 10, flex: 1}]}>{languages["quantity"][code]}</Text>
          </View>
            
          <View style={{flexDirection:'row'}}>
              <SinglePicker title={languages["width"][code]} 
                selectedValue={  props.settings?.width }
                values={stateApi.response} 
                onValueChange={(w) => {
                  const sett = {width: w, 
                    velocity: props.settings.velocity, 
                    quantity: props.settings.quantity}
                  props.onSelectValue(sett)
                  setInternalSetting(sett)
                }}/>
              <SinglePicker title={languages["velocity"][code]} 
                selectedValue={ props.settings?.velocity} 
                values={velocities} 
                onValueChange={(v) => {
                  const sett = {width: props.settings.width, 
                    velocity: v, 
                    quantity: props.settings.quantity}
                  props.onSelectValue(sett)
                  setInternalSetting(sett)
                }}/>
              <SinglePicker title={languages["quantity"][code]} 
                selectedValue={ props.settings?.quantity} 
                values={quantities} 
                onValueChange={(q) => {
                  const sett = {width: props.settings.width, 
                    velocity: props.settings.velocity, 
                    quantity: q}
                  setInternalSetting(sett)
                  props.onSelectValue(sett)}}/>
            </View>
            <View style={{flexDirection:'row'}}>
              <Text style={[TEXT_B, { textAlign: 'center', marginHorizontal: 10, flex: 1}]}>
                {getUnity(metrics, DISTANCE)}
              </Text>
              <Text style={[TEXT_B, { textAlign: 'center', marginHorizontal: 10, flex: 1}]}>
                {getUnity(metrics, VELOCITY)}
              </Text>
              <Text style={[TEXT_B, { textAlign: 'center', marginHorizontal: 10, flex: 1}]}>
                {getUnity(metrics, QUANTITY_DISTANCE)}
              </Text>
            </View>
          </View>
    )
  }

  

  const ValueResult = (stateApi) => {
    
    const NoResult = () => (
      <View style={{marginTop: 30}}>
        <ResultNode notes={languages["no_width_available"][code]} />
      </View>)

    //TODO lingua

    const body = stateApi.response && stateApi.response.length > 0 ? (Platform.OS === 'web' ? BodyWeb() : BodyMobile()) : NoResult()
    return (
      <View>
         {body}
         <TextButton 
          extra_style={{marginTop: 12}}
          disable={stateApi.isLoading} 
          title={languages["check_value_button"][code].toUpperCase()} 
          type={BUTTON_PRIMARY} 
          onPress={() => {
            
            setInternalMessage(null)
            
            internalSetting && callCheckOutput(props.idMachine, 
              props.idFertilizer, 
              internalSetting.width, 
              internalSetting.velocity, 
              internalSetting.quantity, 
              props.paletteSelected.id)
            
            
         }}/>
         { internalMessage && <Text style={TEXT_B_RED}>{internalMessage}</Text> }
         { internalMessageSuccess && <Text style={MEDIUM_B}>{internalMessageSuccess}</Text> }
      </View>
    )
  }


  
  return (
    <View style={{marginHorizontal: Platform.OS === 'web' ? 0 : 12}}>
        {Platform.OS === 'web' &&
          <Text style={getFont(23, DARK_GRAY, DEFAULT_FONT_BOLD)}>
            {languages["set_parameters_label"][code]}
          </Text> }
        { 
            handleLoading(
              props.langSigla, 
              stateApi.isLoading, 
              stateApi.error, 
              ValueResult(stateApi)
            ) 
        }
    </View>)

}
