import React, { useState, useContext } from "react";
import { View, Text, Platform } from 'react-native'
import { BUTTON_FLAT_LINK, DARK_GRAY, TITLE_B, WHITE, LIGHT_GRAY } from "../../../style";
import { TextButton, CustomModal, RowResult, ResultCheck } from "../../General/Other";
import ShowTableExample from './ShowTableExample';
import { UserContext } from '../../../utils/context'
import { formatNumber } from '../../../utils'

export default function Result(props) {

    const appcontext = useContext(UserContext);
    const {code} = appcontext

    
    

    const [modalVisible, setModalVisible] = useState(false);

    const computeResult = (machine, calibration) => {

        

        const x = parseFloat(calibration.b) - parseFloat(calibration.c)
        const j = ( parseFloat(calibration.b) + parseFloat(calibration.c)) / 2
        const y = parseFloat(calibration.d) - parseFloat(calibration.e)
        const k = ( parseFloat(calibration.d) + parseFloat(calibration.e) ) / 2
        const w = j - k

        if (machine.fallpoints && !machine.vanes) {
            return { "v1": "-", "v2": "-", "droppoint": formatNumber(Math.round(((x+w) + (y+w)) / 4.0)) }
        } else {
            return { "v1": formatNumber(Math.round(x + w)), "v2": formatNumber(Math.round(y + w)), "droppoint": "-" }
        }
    }

    const r = computeResult(props.machine, props.calibration)
    const line = <View style={{backgroundColor:DARK_GRAY, width: 0.3}}/>
    
    return (
        <View>
            <CustomModal 
                innerComponet={<ShowTableExample />}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/>
            <View style={{alignItems: 'center', marginVertical: 20}}>
                <ResultCheck />
            </View>
            <Text style={{...TITLE_B, marginLeft: 12}}>
                {appcontext.languages["results_shovel_regolations_kit"][code]}
            </Text>
            <View style={{backgroundColor: Platform.OS === 'web' ? LIGHT_GRAY : WHITE, 
                padding: 20, flexDirection: 'row', marginVertical: 12,
                borderRadius: Platform.OS === 'web' ? 8 : 0 }}>
                <RowResult 
                    label_left={appcontext.languages["regulator_vanes_I_II"][code]}
                    label_right={(r.v1)}/>
                {line}
                <RowResult 
                    label_left={appcontext.languages["regulator_vanes_II"][code]}
                    label_right={(r.v2)}/>
                {line}
                <RowResult 
                    label_left={appcontext.languages["drop_point"][code]}
                    label_right={(r.droppoint)}/>
            </View>
            {props.machine && props.machine.fallpoints && <TextButton 
                type={BUTTON_FLAT_LINK} 
                onPress={() => {
                    Platform.OS === 'web' ? setModalVisible(!modalVisible) : (
                        props.navigation && props.navigation.push('ShowTableExample')
                    )
                }}
            title={appcontext.languages["click_here"][code]} /> }
        </View>
    )
}