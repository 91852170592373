import React, { useEffect, useState, useContext }  from "react";
import { View, Text, FlatList, TouchableOpacity, Platform } from 'react-native';
import { getFont, DARK_GRAY, WHITE, windowWidth, getSquareButton, GREEN, LIGHT_GRAY, DEFAULT_FONT_LIGHT, DEFAULT_FONT } from "../../../style";
import { UserContext } from '../../../utils/context'
import { getPalette } from '../../../api/dcm_api';
import { handleLoading } from '../../Need/'
import { CustomIcon } from "../../General/CustomIcon";
import { ResultNodeAndImage } from '../../General/Other'


export function NoPaletta(props){

    const wCard = Platform.OS !== 'web' ? windowWidth/3*0.7 : 140

    
    useEffect(() => {
        props.onPushRow(null)
      }, [])

    return (
        <TouchableOpacity 
            onPress={() => props.onPushRow(null)} 
            style={{margin: 10, 
                borderColor: true ? GREEN : LIGHT_GRAY, 
                padding: 20,
                borderRadius: 8, borderWidth: 2}}>
            <Text style={getFont(14, DARK_GRAY, DEFAULT_FONT_LIGHT)}>
                {props.title}
            </Text>
            <View style={[getSquareButton(wCard*0.2, true), {position: 'absolute', top: 10, right: 10}]}>
                <CustomIcon name={'checkmark'} size={wCard*0.15} color={WHITE} />
            </View>
        </TouchableOpacity>
    )

}

export function PalettaRow(props){

    const wCard = Platform.OS !== 'web' ? windowWidth/3*0.7 : 140
    

    return (
        <TouchableOpacity 
            onPress={() => props.onPushRow(props.item)} 
            style={{
                borderColor: (props.selectedValue && props.item.id === props.selectedValue.id) ? GREEN : LIGHT_GRAY, 
                padding: 20,
                marginVertical: 3,
                borderRadius: 4, 
                borderWidth: 2}}>
            <Text style={getFont(14, DARK_GRAY, DEFAULT_FONT_LIGHT)}>
                {props.item.name}
            </Text>
            {props.selectedValue && props.item.id === props.selectedValue.id && 
            <View style={[getSquareButton(wCard*0.2, true), {position: 'absolute', top: 10, right: 10}]}>
                <CustomIcon name={'checkmark'} size={wCard*0.15} color={WHITE} />
            </View>}
        </TouchableOpacity>
        
    )

}

export function Palettes(props) {
    //arr.length > 0
    const { languages, code } = useContext(UserContext);
    const arr = props.paletteArray
    
    return (
        <View>
            <Text style={{...getFont(22, DARK_GRAY, DEFAULT_FONT), marginBottom: 10} }>
                {languages["select_paletta"][code] || "Seleziona Paletta"}
            </Text>
            { arr.length > 0 ? 
            <FlatList data={arr} 
                    renderItem={ item => 
                    <PalettaRow 
                        selectedValue={props.paletta} {...item} 
                        onPushRow={ paletta => props.onValueChange(paletta)} />}/> : 
                    
                    <NoPaletta 
                        selectedValue={props.paletta}
                        title={languages["no_paletta"][code] || "nessuna paletta"} 
                        onPushRow={ paletta => props.onValueChange(paletta)}/> } 
            
            
        </View>
    )
}

export default function SetupMachine(props) {

    const [paletta, setPaletta] = useState("");
    const { languages, code } = useContext(UserContext);
    const { machine, fertilizer, eachRow, distributionRow, blade } = props
    
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
    //const [hasBlade, setHasBlade] = useState(false);
    //const [responseFila, setResponseFila] = useState(false);
    

    useEffect(() => {
        props.isValid(false)
        setStateApi({isLoading: true, error: null, response: []})
        getPalette(machine.id, fertilizer.id, 
            props.blade ? 1 : 0, eachRow, distributionRow).then( success => {
            if (success.response) {
                if (success.response.length > 0 ) {
                    props.onSelectSetup(success.response[0])
                }
                
                setStateApi({
                        isLoading: false, 
                        error: null, 
                        response: success.response})
            } else {
                setStateApi({isLoading: false, 
                    error: success.error, 
                    response: []})
            }
        })
    }, [])


    
    return (
        <View style={{marginHorizontal: Platform.OS !== 'web' ? 10 : 0}}> 
            
            { handleLoading(code, stateApi.isLoading, stateApi.error,
                <View>
                    <Palettes 
                        paletteArray={stateApi.response} 
                        paletta={paletta} 
                        onValueChange={(p) => {
                            if (p === null) {
                                props.isValid(true)
                                setPaletta(p)
                                props.onSelectSetup(null)
                            } else {
                                setPaletta(p)
                                const r = stateApi.response.filter( pp => String(pp.id) === String(p.id))
                                if (r.length > 0) {
                                    props.onSelectSetup(r[0]) 
                                }
                                props.isValid(true)
                            } 
                    }}/>
                    { blade && 
                    <ResultNodeAndImage 
                        image={require('../../../images/home_img.png')}
                        notes={languages["warning_select_blade_mess"][code] || "Attenzione! Il convogliatore è stato selezionato"}/>  }
                    { distributionRow === 'center' &&
                    <ResultNodeAndImage 
                        image={require('../../../images/home_img.png')}
                        notes={languages["warning_select_center_row"][code] || "Palette con modalità center"}/> }
                </View>
                )
            }
            
        </View>

    )

    
}
