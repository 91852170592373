import React, { useEffect, useState } from "react";
import { Text, View } from 'react-native';
import { getParameterByName, removeParameter } from '../utils'
import GreenLine from './GreenLine'
import Header from './Header.web'
import Kit from "./Utils/Kit/Kit";
import Home from './Home/Home'
import HeaderBlank from './HeaderBlank'
import SettingsLogin from './SettingsLogin/SettingsLogin'

import _ from 'underscore'
import "./header.css"

export default function AppContainerRefactor() {

    const [who, setWho] = useState("home");
    const [favorite, setFavorite] = useState(null);
    const [restart, setRestart] = useState(false);

    const email = getParameterByName('email')
    const url = new URL(window.location.href);
    const code = _.last(url.pathname.split('/'))

    useEffect(() => {
        if (email && code) {
          setWho("reset")
        }
    
      }, [who])

      const router = {
        "home": {
          "component": <Home favorite={favorite} restart={restart}/>
        },
        "settings": {
          "component": <SettingsLogin onFavoriteClick={(f) => {
            setWho("home")
            setFavorite(f)
          }}/>
        },
        "kit": {
          "component": <Kit /> 
        },
        "factor": {
          "component": <View><Text>factor</Text></View>
        },
        "reset": {
          "component": <View><Text>reset</Text></View>
        }
      }

    
      

      return (
        <div>
          <div className="header">
          { who !== 'reset' ? 
            <Header main={who} onChangeMain={ w => {
              setWho(w)
              setRestart(!restart)
              setFavorite(null)
            }}/> : 
            <HeaderBlank onChangeMain={ w => removeParameter()}/> }
            <GreenLine />
          </div> 
          <View style={{marginTop: 60}}>
            { router[who].component } 
          </View>
        </div>
      )

      


      
}

