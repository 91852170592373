import React, { useState, useContext, useEffect } from "react";
import { ScrollView, Text, View, TouchableOpacity, Image, Platform, Linking } from 'react-native'
import { UserContext } from '../../../utils/context'
import { container, BUTTON_PRIMARY, getSquareButton, BUTTON_SECONDARY, WHITE, 
    TEXT_B, WARNING_LIGHT, TITLE_B, getFont, DARK_GRAY, RED, DEFAULT_FONT, DEFAULT_FONT_LIGHT } from "../../../style";
import { generateTxt, generateTxtBordura, section_flow_reduction, section_bordura, section_input_data, section_surface, section_settings_machine, section_settings_isobus } from './utilsResults'
import { addFavorite, getOutput, getOpenPosition } from '../../../api/dcm_api'
import { TextIconButton, ShowImage, CustomModal } from '../../General/Other'
import RegulatorVRI from './RegulatorVRI'
import UpdateValue from './UpdateValue'
import CustomSegmentedControl from '../../General/CustomSegmentedControl'
import { CustomIcon } from '../../General/CustomIcon'

export default function Result(props) {

    const { machine, fertilizer, family, settings, paletta, elettric } = props
    const { languages, code, metrics, getUserApi, setMessage, user } = useContext(UserContext);
    const [ outStateApi, setOutStateApi ] = useState({isLoading: false, error: null, response: null});
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: null});

    

    useEffect(() => {
        setOutStateApi({isLoading: true, error: null, response: null})        
        getOutput(code, 
            machine.id, 
            fertilizer.id, 
            settings ? settings.width : 0, 
            settings ? settings.velocity : 0, 
            settings ? settings.quantity: 0, 
            paletta ? paletta.id : 0, 
            props.blade ? 1 : 0, 
            props.cmHeightPianta ? props.cmHeightPianta : 0 ).then( success => {
                if (success.response) {
                    setOutStateApi({isLoading: false, error: null, response: success.response})
                } else {
                    setOutStateApi({isLoading: false, error: success.error, response: null})
                }
        })
    }, [])

    const createBody = (out) => {
        const input_sec = generateTxt(section_input_data(machine, family, fertilizer, settings, elettric, languages, code, metrics))
        const surface = out.response ? generateTxt(section_surface(out.response, languages, code, metrics)) : ""
        const sett_machine = out.response ? generateTxt(section_settings_machine(out.response, elettric, languages, code, metrics)) : ""
        const isobus = out.response ? generateTxt(section_settings_isobus(out.response, fertilizer, settings, elettric, languages, code, metrics)) : ""
        const b_standad = out.response?.border?.standard ? generateTxtBordura(section_bordura(out.response?.border?.standard, languages["border_standard"][code], languages, code)) : ""
        const b_optional = out.response?.border?.optional ? generateTxtBordura(section_bordura(out.response?.border?.optional, "Bordura optional", languages, code)) : ""
        const reduction = out.response?.reduction?.optional ? generateTxtBordura(section_flow_reduction(out.response?.reduction?.optional, languages, code)) : ""
        const note = fertilizer.description
        const fall = out.response?.fallpoints?.fallPoints ? out.response?.fallpoints?.fallPoints : []
        var fallpoints_v = ""
        fall.forEach( f => {
            fallpoints_v = fallpoints_v + "\t\t" + f.fallPointPos + ": " + f.meters + "\n"
        })
        return input_sec + surface + sett_machine + isobus + fallpoints_v + b_standad + b_optional + reduction + note
        
    }

    return (
        <ScrollView style={{marginHorizontal: Platform.OS === 'web' ? 0 : 10}} 
            showsVerticalScrollIndicator={false}>
            <HeaderButtons 
                isLoadingSave={stateApi.isLoading}
                onSaveClick={() => {
                        
                        setStateApi({isLoading: true, error: null, response: null})
                        addFavorite({ "machine": machine, "family": family, "product": fertilizer ,"settings": settings})
                            .then( s => {
                                getUserApi()
                                if (s.response){
                                    setStateApi({isLoading: false, error: null, response: s.response})
                                    setMessage({message: languages["element_save"][code], type: "SUCCESS"})
                                } else {
                                    setStateApi({isLoading: false, error: s.error, response: null})
                                    setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                                }
                            })   
                    }} 
                    onShareClick={() => {
                        if (user) {
                            const body = createBody(outStateApi)
                            const subject = "Result"
                            const mittente = "dcm@spreadshit.com"
                            Linking.openURL(`mailto:${mittente}?subject=${subject}&body=${body}`)
                        } else {
                            setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                        }
                        
                }} />
            { outStateApi.response && <ResultNode notes={fertilizer.description}/>}
            <ResultSection 
                machine={machine}
                elettric={elettric} 
                data={
                    section_input_data(machine, family, fertilizer, 
                        settings, elettric, languages, code, metrics)} 
                navigation={props.navigation} />
            { outStateApi.response && 
                <ResultSection 
                    machine={machine}
                    elettric={elettric}
                    data={section_surface(outStateApi.response, languages, code, metrics)} 
                    navigation={props.navigation} /> }
            { outStateApi.response && 
                <ResultSection 
                    updateValueForMobile={props.updateValue}
                    responseOpenPosition={props.responseOpenPosition}
                    elettric={elettric}
                    machine={machine}
                    data={section_settings_machine(outStateApi.response, elettric, languages, code, metrics)} 
                    navigation={props.navigation} /> }
            { outStateApi.response && elettric && 
                <ResultSection 
                    elettric={elettric}
                    machine={machine}
                    data={section_settings_isobus(outStateApi.response, fertilizer, settings, elettric, languages, code, metrics)} 
                    navigation={props.navigation} /> }
            { outStateApi.response?.border?.standard && 
                    <ResultSectionWithOption 
                        machine={machine}
                        data={section_bordura(outStateApi.response?.border?.standard, languages["paletta_special_title"][code], languages, code)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.border?.optional && 
                    <ResultSectionWithOption 
                        machine={machine}
                        data={section_bordura(outStateApi.response?.border?.optional, languages["border_standard"][code], languages, code)} 
                        navigation={props.navigation} /> }
            { outStateApi.response?.reduction?.optional && 
                    <ResultSectionWithOption 
                        machine={machine}
                        data={section_flow_reduction(outStateApi.response?.reduction?.optional, languages, code)} 
                        navigation={props.navigation} /> }
            
        </ScrollView>
    )
}

export function HeaderButtons(props){
    const { languages, code } = useContext(UserContext);
    return (
        <View style={{flexDirection: 'row', justifyContent:'flex-end'}}>
            <TextIconButton style={[container.select_button, { marginBotton: 10, marginRight: 5 }]} 
                onPress={() => props.onSaveClick()}
                extra_style={{marginRight: 10}}
                type={BUTTON_PRIMARY}
                icon="star" 
                title={languages['save'][code].toUpperCase()}/>
            <TextIconButton 
                onPress={() => props.onShareClick()}
                type={BUTTON_SECONDARY}
                icon="share" 
                title={languages['share'][code].toUpperCase()}/>
        </View>
    )
}


export function ResultSection(props){

    const [openPositionStatus, setOpenPositionStatus] = useState(null);

    

    return (
        <View style={{ marginTop: 10}}>
            <Text style={TITLE_B}>{props.data.title}</Text>
            {
                props.data.rows.map( (r, index) => 
                    <ResultRowParent 
                        responseOpenPosition={props.responseOpenPosition}
                        updateValueForMobile={props.updateValueForMobile}
                        openPositionStatus={openPositionStatus}
                        updateAllRows={(p) => setOpenPositionStatus(p)}
                        machine={props.machine}
                        openPositionValue={ props.data.rows.filter( p => p.titleRow === "open_position") }
                        navigation={props.navigation} 
                        key={index} 
                        {...r} />)
            }
        </View>
    )
}

export function ResultRowParent(props) {

    

    const { code } = useContext(UserContext);

    const [dataOnLayer, setDataOnLayer] = useState(null);
    const [modalToShow, setModalToShow] = useState(false);
    const [nameModalToShow, setNameModalToShow] = useState('');

    const [ updateRightCell, setUpdateRightCell ] = useState(null);
    const [ newOpenPosition, setNewOpenPosition ] = useState(null);

    const originalRightValue = props.rightText
    const openPositionValue = props.openPositionValue && props.openPositionValue.length > 0 ? props.openPositionValue[0].rightText : ''
 
    

    const onClickUpdateAction = (editValue, oldValue, openPositionValue) => {


        if (Platform.OS === 'web') {
            setModalToShow(!modalToShow)
            setNameModalToShow('')
        } 
        
        setUpdateRightCell({'value': editValue, 'target': 'kg_min'}) //Update right value
        getOpenPosition(
            props.machine ? props.machine.id : 0, 
            openPositionValue, 
            oldValue,
            editValue, 
            code ).then( success => {
                if (success.response) {
                    props.updateAllRows({'value': success.response.newPosition, 'target': 'open_position', 'status': true})    
                } else {
                    props.updateAllRows({'value': success.error.message, 'target': 'open_position', 'status': false})
                } 
            })

    }

    useEffect(() => {
        if (props.openPositionStatus && props.openPositionStatus.target === props.titleRow) {
            setNewOpenPosition(props.openPositionStatus)
        }
    }, [props.openPositionStatus])

    return (
        <View>
            <CustomModal 
                innerComponet={<ShowImage icon={dataOnLayer}/>}
                modalVisible={modalToShow && nameModalToShow === 'ShowImage'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <CustomModal 
                innerComponet={<RegulatorVRI data={dataOnLayer}/>}
                modalVisible={modalToShow && nameModalToShow === 'RegulatorVRI'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <CustomModal 
                innerComponet={<UpdateValue 
                    data={dataOnLayer} 
                    onClickAction={(editValueFromText, s, t) => {
                        onClickUpdateAction(editValueFromText, originalRightValue, openPositionValue)}
                    }/>}
                modalVisible={modalToShow && nameModalToShow === 'UpdateValue'} 
                onClose={() => {
                    setModalToShow(!modalToShow)
                    setNameModalToShow('')
                }}/>
            <ResultRow 
                onClickIcon={(icon) => { 
                    
                    if (Platform.OS === 'web') {
                        setDataOnLayer(icon)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('ShowImage')
                    } else {
                    
                        props.navigation && props.navigation.push('ShowImage', { image: icon })
                    }
                }}
                onClickRow={(data) => {
                
                    if (Platform.OS === 'web') {
                        setDataOnLayer(data)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('RegulatorVRI')
                    } else {
                        props.navigation && props.navigation.push('RegulatorVRI', { data: data })
                    }
                }}
                onClickActionButton={(data) => {
                    if (Platform.OS === 'web') {
                        setDataOnLayer(data)
                        setModalToShow(!modalToShow)
                        setNameModalToShow('UpdateValue')
                    } else {
                        props.navigation && props.navigation.push('UpdateValue', 
                        { 
                            data: data, 
                            machine_id: props.machine ? props.machine.id : -1, 
                            openPositionValue: openPositionValue 
                        })
                    }
                }}
                updateRightCell={updateRightCell}
                newOpenPosition={newOpenPosition}
                {...props} />
        </View>
    )
}

export function ResultRow(props) {

    const hasIcon = (props.icona && props.icona !== '') ? true : false
    
    return (
        <View>
            <TouchableOpacity style={[container.row_result, { marginVertical: 3}]}>
                <TouchableOpacity 
                    onPress={() => props.isNavigation && props.onClickRow(props.data)} 
                    style={{
                        flexDirection:'row',
                        alignItems:'center'}}>
                
                    {  hasIcon && 
                        <TouchableOpacity onPress={() => props.onClickIcon(props.iconaLarge || props.icona)}>
                            <Image 
                                style={{'width':30, 'height':30, marginRight: 7, resizeMode: 'contain'}} 
                                source={props.icona} />
                        </TouchableOpacity>}
                    <View style={ {
                        flexDirection:'row', 
                        flex:1, 
                        justifyContent: 'space-between', 
                        alignItems: 'center'}}>
                        <Text style={getFont(16, DARK_GRAY, DEFAULT_FONT_LIGHT)}>{props.leftText || ''}</Text> 
                        <RightView {...props}/>
                    </View>
                    { props.actionView && 
                        <TouchableOpacity 
                            onPress={() => {
                                props.onClickActionButton(props.rightText)
                            }}
                            style={{...getSquareButton(22, true), marginStart: 12}}>
                            <CustomIcon name="create" size={22} color={WHITE}/>
                        </TouchableOpacity>
                    }
                </TouchableOpacity>
            </TouchableOpacity>
        </View>
    )
}



export function RightView(props) {
    const NewAndOldRight = (p) => {
        return (
            <View style={{flexDirection: 'row'}}>
                <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {marginHorizontal: 15}]}>
                    {p.newValue || ''}
                </Text>
                <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {textDecorationLine: 'line-through'}]}>
                    {p.oldValue}
                </Text>
            </View> ) 
    }

    

    const RightText = (p) => <Text style={[getFont(16, DARK_GRAY, DEFAULT_FONT), {marginHorizontal: 15}]}>{p.rightText}</Text>

    if (Platform.OS === 'web') {
        //per il web arrivano questi dati
        if (props.updateRightCell) {
            //aggiornare per effetto del edit del utente
            return <NewAndOldRight newValue={props.updateRightCell.value} oldValue={props.rightText}/>
        } if (props.newOpenPosition && props.newOpenPosition.status) {
            //aggiornare per effetto della risposta positiva del backend
            return <NewAndOldRight newValue={props.newOpenPosition.value} oldValue={props.rightText}/>
        } else {
            return <RightText rightText={props.rightText}/>
        }
    } else {
        //per il mobile invece arriva updateValueForMobile (valore del edit inserito dal utente) responseOpenPosition risposta del backend
        if (props.updateValueForMobile && props.titleRow === 'kg_min') {
            //edit statico
            return <NewAndOldRight newValue={props.updateValueForMobile} oldValue={props.rightText}/>
        } if (props.responseOpenPosition && props.titleRow === 'open_position' && props.responseOpenPosition.status) {
            //edit per risposta positiva del backend
            return <NewAndOldRight newValue={props.responseOpenPosition.value} oldValue={props.rightText}/>
        } else {
            console.log('uqqaa')
            return <RightText rightText={props.rightText}/>
        }
    }
    
    

    
        
}


export function ResultSectionWithOption(props){

    
    const [indexSegmented, setIndexSegmented] = useState(0);
    
    


    return (
        <View style={{padding: 4, marginTop: 10}}>
            <Text style={TITLE_B}>{props.data.title}</Text>
            <CustomSegmentedControl
                    values={props.data.title_segmented}
                    selectedIndex={indexSegmented}
                    onChange={(event) => {
                        setIndexSegmented(event)
                    }} />
            {
                props.data.rows.map( (r, index) => 
                    <ResultRowParent 
                        openPositionValue={ props.data.rows.filter( p => p.titleRow === "open_position") }
                        navigation={props.navigation}
                        key={index} 
                        icona={r.icona} 
                        iconaLarge={r.iconaLarge}
                        titleRow={r.titleRow}
                        rightText={r.rightText[indexSegmented]} 
                        leftText={r.leftText}  />)
            }
        </View>
    )
}

export function ResultNode(props){
    return (
        <View style={{padding: 14, marginTop: 5, backgroundColor: WARNING_LIGHT, borderRadius: 5}}>
            <Text style={[TEXT_B, {padding: 10}]}>
                {props.notes}
            </Text>
        </View>
    )
}


