import React, { useEffect, useState, useContext }  from "react";
import { View, Text, TouchableOpacity, Platform, FlatList, useWindowDimensions } from 'react-native';
import { getFertilizerFamilies } from '../../../api/dcm_api';
import { TEXT_B, windowWidth, TITLE_B, GREEN, MEDIUM_B, container, WHITE, getSquareButton, BUTTON_SECONDARY } from "../../../style";
import { handleLoading } from '../../Need/'
import { TextButton, ORView } from '../../General/Other'
import _ from 'underscore'
import { UserContext } from '../../../utils/context'
import { CustomIcon } from "../../General/CustomIcon";
import ProgressiveImage from '../../General/ProgressiveImage'

export function FamiliesRow(props){

    const wCard = Platform.OS !== 'web' ? windowWidth/3*0.7 : 140

    
    return (
        <TouchableOpacity 
            style={{...container.card, margin: 10}} onPress={() => props.onPushRow(props.item)}>
            <ProgressiveImage img_url={props.item.img_url} width={wCard} height={wCard}
                style_image={{width: wCard, height: wCard, 
                borderColor: GREEN, borderRadius: 4, borderWidth: props.item.id === props.selectedId ? 2 : 0}} />
            {props.item.id === props.selectedId && 
            <View style={[getSquareButton(wCard*0.2, true), {position: 'absolute', top: 10, right: 10}]}>
                <CustomIcon name={'checkmark'} size={wCard*0.15} color={WHITE} />
            </View>}
            <Text style={[MEDIUM_B, {textAlign: 'center', maxWidth: wCard, marginTop: 10}]}>{props.item.name}</Text>
        </TouchableOpacity>
    )
}




export default function FertilizerFamilies(props) {

    const { languages, code, setMessage, user } = useContext(UserContext);
    //const { languages, code, metrics, getUserApi, setMessage, user } = useContext(UserContext);
    const { width } = useWindowDimensions();
    
    const [selectedId, setSelectedId] = useState(props.idFamily);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});

    //const calc_num = Math.floor(width*0.8 / 240)
    const calc_num = width < 400 ? 2 : Math.floor(width*0.8 / 140)

    useEffect(() => {
        props.isValid(false)
        setStateApi({isLoading: true, error: null, response: []})
        getFertilizerFamilies(code).then( success => {
            if (success.response) {
                props.isValid( props.idFamily !== -1 )
                const sort_success = _.sortBy(success.response, 'sequence')
                setStateApi({isLoading: false, error: null, response: sort_success})
            } else {
                setStateApi({isLoading: false, error: success.error, response: []})
            }
        })
    }, [])

    return (
        
        <View>
            <Text style={[TITLE_B, {marginLeft: Platform.OS === 'web' ? 0 : 20}]}>
                {languages["find_for_family"][code]}
            </Text>
            { 
                handleLoading(code, stateApi.isLoading, stateApi.error,
                    <View style={{paddingHorizontal: 15, alignItems: 'center'}}>
                        <FlatList data={stateApi.response} 
                            renderItem={({item, index}) => 
                                <FamiliesRow index={index} key={item.id} item={item} selectedId={selectedId}  
                                    onPushRow={ family => {
                                        props.onSelectFamily(family)
                                        setSelectedId(family.id)
                                        props.isValid(true)
                                    }}/>}
                            keyExtractor={item => item.id}
                            key={calc_num} numColumns={ Platform.OS === 'web' ? calc_num : 3} 
                            />
                    </View>
                ) 
            }
            <ORView orText={languages["or"][code]}/>
            <Text style={[TEXT_B, {textAlign: 'center', 
                    marginBottom: 20,
                    paddingHorizontal: '5%'}]}>{languages["description_distribution_configurator"][code]}</Text>
            <View style={{alignItems: 'center'}}>
                <TextButton extra_style={{width: 'auto'}}
                    onPress={() => {
                        user ? props.onSearchClick() : setMessage({message: languages["element_not_save"][code], type: "ERROR"})
                    }} 
                    title={languages["analisi_concime"][code].toUpperCase()}
                    type={BUTTON_SECONDARY}/>
            </View>
        
            
            { Platform.OS !== 'web' && <View style={{marginVertical: 50}}></View> }
        </View>
        
    )
}