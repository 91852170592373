import React, { useContext, useState } from "react";
import { Text, View, Platform, ScrollView, Image } from 'react-native'
import { DEFAULT_PAD, TEXT_B, BUTTON_PRIMARY, BUTTON_FLAT_LINK, DARK_GRAY, getFont, DEFAULT_FONT } from "../../style";
import { TextButton } from "../General/Other";
import { UserContext } from '../../utils/context'
import { CustomModal } from '../General/Other'
import { DisclaimerConteiner } from '../SettingsLogin/Disclaimer'

export default function StartConfigurator(props) {

    const { languages, code } = useContext(UserContext);
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <ScrollView style={{paddingTop: DEFAULT_PAD}}>
            { Platform.OS === 'web' && <CustomModal 
                titleModal={'Title'}
                innerComponet={<DisclaimerConteiner />}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/> }
            <View style={{alignItems: 'center'}}>
                <Text style={[ getFont(32, DARK_GRAY, DEFAULT_FONT), {
                marginVertical: DEFAULT_PAD, width: "80%", lineHeight: 30, 
                textAlign: 'left'}]}>
                    {languages["title_configurator"][code]}
                </Text>
                <Text style={[ TEXT_B, {width: "80%", marginVertical: DEFAULT_PAD} ]}>
                    {languages["body_configurator"][code]}
                </Text>
                <View>
                    
                    <TextButton 
                        title={languages["alert_configurator_title"][code]}
                        onPress={() => { 
                            Platform.OS === 'web' ? setModalVisible(true) : props.navigation.push('Disclaimer')
                        }}  
                        type={BUTTON_FLAT_LINK}/>
                </View>
                
                <TextButton extra_style={{width: "auto"}}
                    title= {languages["start"][code]}
                    onPress={() => props.startConfigurator(true)} 
                    type={BUTTON_PRIMARY}/>
                <Image style={{height: 230, width: 230, resizeMode: 'contain'}} 
                    source={require('../../images/home_img.png')}/>
                
                
                <View style={{width: '100%', height: 100 }}/>
            </View>
        </ScrollView>
    )

    
}