import React, { useEffect, useState, useContext }  from "react";
import { View, Text, TouchableOpacity, FlatList, Platform , useWindowDimensions, } from 'react-native'
import { getShapes } from '../../../api/dcm_api'
import { container, MEDIUM_B, WHITE, DARK_GRAY, GREEN } from '../../../style'
//import Ionicons from 'react-native-vector-icons/Ionicons';
import { CustomIcon } from "../../General/CustomIcon";
import { CustomModal, ShowImage } from '../../General/Other'
import { handleLoading } from '../../Need/'
import { UserContext } from '../../../utils/context'
import ProgressiveImage from '../../General/ProgressiveImage'


export function ShapeRow(props){
    
    const size = 30
    const style_button = { 
        width: size,
        height: size,
        borderRadius: size/2,
        justifyContent:'center', 
        alignItems:'center',
        backgroundColor: DARK_GRAY,
        borderColor: DARK_GRAY
    }
    

    return (
        <TouchableOpacity 
            style={{...container.card, margin: 10}} onPress={() => props.onPushRow(props.item)}>
            <ProgressiveImage img_url={props.item.img_url} width={140} height={140} style_image={{width: 140, height: 140, 
                    borderColor: GREEN, borderRadius: 4, borderWidth: props.selectedId === props.item.id ? 2 : 0} }/>
            <TouchableOpacity 
                style={[style_button, {position: 'absolute', top: 8, right: 8}]}
                onPress={() => props.onExpandButtonClick(props.item)} >
                <CustomIcon name={'expand'} size={15} color={WHITE} />
            </TouchableOpacity>
            <Text style={[MEDIUM_B, {textAlign: 'center', maxWidth: 130, marginTop: 10}]}>{props.item.name}</Text>
            { props.index === props.total-1 && <View style={{height: 100}}/> }
        </TouchableOpacity>
    )
}


export default function Shapes(props) {

    const { width } = useWindowDimensions();
    const { code, languages } = useContext(UserContext);
    const [stateApi, setStateApi] = useState({isLoading: false, error: null, response: []});
    const [modalVisible, setModalVisible] = useState(false);
    const [iconToShow, setIconToShow] = useState(null);
    const calc_num = width < 400 ? 2 : Math.floor(width*0.8 / 140)
    const [selectedId, setSelectedId] = useState(props.shape ? props.shape.id : -1);

    

    useEffect(() => {
        props.isValid(false)
        setStateApi({isLoading: true, error: null, response: []})
        getShapes(code).then( success => {
            props.isValid(selectedId !== -1)
            if (success.response) {
                setStateApi({isLoading: false, error: null, response: success.response})
            } else {
                setStateApi({isLoading: false, error: success.error, response: []})
            }
        })
    }, [])


    return (
        <View style={{paddingHorizontal: 15, alignItems: 'center'}}>
            <CustomModal 
                titleModal={'Title'}
                innerComponet={<ShowImage icon={iconToShow}/>}
                modalVisible={modalVisible} 
                onClose={() => setModalVisible(!modalVisible)}/>
            <Text style={MEDIUM_B}>{languages["choose_shape"][code]}</Text>
            {
                handleLoading(code, stateApi.isLoading, stateApi.error, 
                    <FlatList style={{paddingBottom: 200}} 
                        key={calc_num} 
                        numColumns={ calc_num } 
                        data={stateApi.response} 
                        renderItem={({item, index}) => 
                            <ShapeRow key={index} selectedId={selectedId} total={stateApi.response.length}
                                item={item} index={index}
                                onExpandButtonClick={(ss) => {
                                    if (Platform.OS === 'web') {
                                        setModalVisible(!modalVisible)
                                        setIconToShow({uri: ss.img_url})
                                    } else {
                                        props.navigation && props.navigation.push('ShowImage', { image: {uri: ss.img_url}})
                                    }
                                }}
                                onPushRow={(ss) => {
                                    setSelectedId(ss.id)
                                    props.onSelectShape(ss)
                                    props.isValid(true)
                                }}/> } 
                        keyExtractor={item => item.id} /> )
            }
            <View style={{height:30}}/> 
        </View>
    )
}