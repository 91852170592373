import React, { useContext } from "react";
import { View, Text, ScrollView } from 'react-native'
import { getFont, WHITE, GREEN, DEFAULT_FONT } from "../../../style";
import { UserContext } from '../../../utils/context'
import { getUnity, converValue, DISTANCE } from '../../../metrics'
import _ from 'underscore'

export const RowTable = (p) => {
    
    return (
        <View style={{backgroundColor: GREEN, flexDirection: 'row', 
            paddingVertical: 4, paddingHorizontal: 12, borderRadius: 4, marginVertical: 3}}>
            <Text style={{...getFont(11,WHITE), textAlign: 'center', flex: 1}}>{p.item.item ? p.item.item.scala_positon : p.item.scala_positon }</Text>
            <View style={{width:1, backgroundColor: WHITE}}/>
            <Text style={{...getFont(11,WHITE), textAlign: 'center', flex: 1}}>{p.item.item ? p.item.item.meters : p.item.meters }</Text>
        </View>
    )
}

export const HeaderTable = (p) => {
    return (
        <View style={{backgroundColor: WHITE, flexDirection: 'row', 
            paddingVertical: 4, paddingHorizontal: 12, borderWidth: 2, 
            marginVertical: 3, borderColor: GREEN, borderRadius: 4}}>
            <Text style={{...getFont(12,GREEN, DEFAULT_FONT), textAlign: 'center', flex: 1}}>{p.scala_positon}</Text>
            <View style={{width:1, backgroundColor: GREEN}}/>
            <Text style={{...getFont(12,GREEN, DEFAULT_FONT), textAlign: 'center', flex: 1}}>{p.meters}</Text>
        </View>
    )
}


export default function ShowTableExample(props) {

    const appcontext = useContext(UserContext);

    const scala_positon = _.range(0,12,1)
    const meters = [0,0,9,12,15,18,9,12,15,18,0,0]
    const merge_data = _.zip(scala_positon, meters)
        .map( el => { return {'scala_positon': el[0], 'meters': converValue(el[1], appcontext.metrics, DISTANCE )}})

    
    
    return (
        <ScrollView style={{padding: 12}}>
            
            <View style={{flexDirection: 'row', marginVertical: 5}}>
                <View style={{ flex:1, marginRight:3}}>
                    <HeaderTable 
                        scala_positon={appcontext.languages["scala_position"][appcontext.code]} 
                        meters={getUnity(appcontext.metrics, DISTANCE)} />
                    { _.first(merge_data, 6).map( (m,index) => <RowTable key={index} item={m} />) }
                    <Text style={getFont(12)}>
                        {appcontext.languages["fig_1"][appcontext.code]}
                    </Text>
                </View>
                <View style={{flex: 1, marginLeft: 3}}>
                    <HeaderTable 
                        scala_positon={appcontext.languages["scala_position"][appcontext.code]} 
                        meters={getUnity(appcontext.metrics, DISTANCE)} />
                    { _.rest(merge_data, 6).map( (m,index) => <RowTable key={index} item={m} />) }
                    <Text style={getFont(12)}>
                        {appcontext.languages["fig_2"][appcontext.code]}
                    </Text>
                </View>
            </View>
            <Text style={{...getFont(12), marginTop: 12}}>
                {appcontext.languages["example_show_table"][appcontext.code].replace(/\\n/g,'\n')} 
            </Text>
        </ScrollView>
        
    )
}