import { View, Text, Platform } from 'react-native'
import React, { useState, useRef, useContext }  from "react";
import { CustomTextInput, TextButton } from "../../General/Other";
import { MEDIUM_B, BUTTON_PRIMARY  } from '../../../style'
import { UserContext } from '../../../utils/context'

export default function UpdateValue(p){

    
    
    const data = Platform.OS === 'web' ? p.data : p.route.params.data
    const machine_id = Platform.OS === 'web' ? -1 : p.route.params.machine_id
    const openPositionValue = Platform.OS === 'web' ? p.openPositionValue : p.route.params.openPositionValue

    

    const onClickAction = Platform.OS === 'web' ? p.onClickAction : p.route.params.onClickAction
    const { languages, code } = useContext(UserContext);
    
    const [textW, setTextW] = useState(data)
    var textInputWidth = useRef(null)

    return (
        <View style={{'paddingHorizontal': Platform.OS !== 'web' ? 10 : 0, marginTop: Platform.OS !== 'web' ? 10 : 0}}>
            <Text style={MEDIUM_B}>
                {languages["update_value_message"][code]} 
            </Text>
            <CustomTextInput 
                ref={(ref) => textInputWidth.current = ref} 
                accept="float"
                keyboardType="numbers-and-punctuation" 
                returnKeyType='done'
                style={{padding: 5, ...MEDIUM_B}} 
                onChangeText={(t) => {
                    setTextW(t)
                }} 
                text={String(textW)}
                placeholder={languages["insert_value_message"][code]} />
            <TextButton 
                type={BUTTON_PRIMARY}
                onPress={() => {
                    
                    if (Platform.OS === 'web') {
                        onClickAction(textW, data, openPositionValue)
                    } else {
                        p.onClickAction(textW, data, openPositionValue, machine_id)
                        p.navigation.goBack()
                    }
                         
                }}
                title={languages["update"][code].toUpperCase()}/>
        </View>
    )
}